import Courses from "views/Courses";
import Introduction from "views/Introduction";
import Structure from "views/Structure";
import UserProfile from "views/UserProfile";
import Procedures from "views/Procedures";
import CompanyDocs from "views/CompanyDocs";
import PPPs from "views/PPPs";
import Policies from "views/Policies";
import Roles from "views/Roles";
import Performance from "views/Performance";
import Training from "views/Training";
import { HashRouter as BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

var routes = [
  {
    path: "/introduction",
    name: "Introduction",
    rtlName: "Introduction",
    icon: "",
    component: <Introduction />,
    layout: "/admin",
  },
  {
    path: "/structure",
    name: "Galattica Team",
    rtlName: "Company Structure",
    icon: "",
    component: <Structure />,
    layout: "/admin",
  },
  {
    path: "/procedures",
    name: "Culture, procedures and policies",
    icon: "",
    component: <Procedures />,
    layout: "/admin",
  },

  {
    path: "/roles",
    name: "Roles",
    rtlName: "Roles",
    icon: "",
    component: <Roles />,
    layout: "/admin",
  },
  {
    path: "/training",
    name: "Training",
    rtlName: "Training",
    icon: "",
    component: <Training />,
    layout: "/admin",
  },
  {
    path: "/performance",
    name: "Peformance Evaluation",
    rtlName: "Performance",
    icon: "",
    component: <Performance />,
    layout: "/admin",
  },
  {
    path: "/courses",
    name: "Courses",
    rtlName: "Courses",
    icon: "",
    component: <Courses />,
    layout: "/admin",
  },

  {
    path: "/profile",
    name: "Profile",
    rtlName: "Profile",
    icon: "",
    component: <UserProfile />,
    layout: "/admin",
  },
];

export default routes;
