import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import "assets/css/black-dashboard-react.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import CoursePreview from "views/CoursePreview";
import Course from "views/Course";
import Admin from "layouts/Admin/Admin";
import ProceduresPreview from "views/ProcedurePreview";
import Landing from "views/Landing";
import RolesDetails from "views/RolesDetails";
import JobRolesPreview from "views/job-roles-preview";
import Login from "views/Login";
import SignupForm from "views/SignupForm";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <HashRouter>
        <Routes>
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/course-preview/:courseId" element={<CoursePreview />} />
          <Route path="/admin/procedures-preview/:procedureId" element={<ProceduresPreview />} />
          <Route path="/admin/roles-preview/:roleId" element={<JobRolesPreview />} />
          <Route path="/admin/course/:courseId" element={<Course />} />
          <Route path="/" element={<Navigate to="/landing" replace />} />
        </Routes>
      </HashRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);
