import React from "react";
import { Card, CardBody } from "reactstrap";
import "./LeftPanel.css";
import { backgroundColors } from "contexts/BackgroundColorContext";
import { NavLink } from "react-router-dom";

const LeftPanel = ({ course,lessons, onLessonChange }) => {
  return (
    <div className="left-panel" style={{background:"linear-gradient(0deg, #0098f0 0%, #e14eca 100%)"}}>
      <h2>{course}</h2>
      {lessons.map((lesson, index) => (
    
<NavLink
onClick={() => onLessonChange(lesson)}
className="nav-link"
style={{color:"white",    textTransform: "uppercase",
cursor: "pointer",
fontSize: "14px",
padding: "10px 8px",
lineHeight: "1.625rem",
fontWeight:"400"
}}
>
{/*<i className={prop.icon} />*/}
{lesson.title}
</NavLink>
      ))}
    </div>
  );
};

export default LeftPanel;
