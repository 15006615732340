import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import { useParams, Link } from "react-router-dom";
/* import "./job-roles-preview.css"; */

const jobRolesData = [
  {
    id: 1,
    title: () => <h2>Software Developer</h2>,
    description: () => (
      <div>
        <p>
          Develop and maintain software applications. Collaborate with
          cross-functional teams to design, develop, and test new features.
        </p>
        <p>
          <strong>Key Responsibilities:</strong>
        </p>
        <ul>
          <li>Write clean and efficient code.</li>
          <li>Collaborate with UX/UI designers for software design.</li>
          <li>Test and deploy applications.</li>
        </ul>
      </div>
    ),
  },
  {
    id: 2,
    title: () => <h2>Software Network Engineer</h2>,
    description: () => (
      <div>
        <p>
          Design and implement network solutions for software systems. Ensure
          the stability and security of the company's network infrastructure.
        </p>
        <p>
          <strong>Key Responsibilities:</strong>
        </p>
        <ul>
          <li>Design and implement network architecture.</li>
          <li>Configure and troubleshoot network devices.</li>
          <li>Collaborate with IT teams for network security.</li>
        </ul>
      </div>
    ),
  },
  {
    id: 3,
    title: () => <h2>IT Support</h2>,
    description: () => (
      <div>
        <p>
          Provide technical support for hardware and software issues.
          Troubleshoot and resolve IT-related problems for internal users.
        </p>
        <p>
          <strong>Key Responsibilities:</strong>
        </p>
        <ul>
          <li>Provide helpdesk support to end-users.</li>
          <li>Install and configure software and hardware.</li>
          <li>Troubleshoot technical issues and escalate when necessary.</li>
        </ul>
      </div>
    ),
  },
  {
  id: 4,
  title: () => <h2>Business Admin</h2>,
  description: () => (
    <div>
      <p>
        Assist in coordinating office activities and operations to secure efficiency and compliance with company policies.
      </p>
      <p>
        <strong>Key Responsibilities:</strong>
      </p>
      <ul>
        <li>Manage agendas, travel arrangements, and appointments.</li>
        <li>Manage/screen phone calls and correspondence (e-mails, letters, packages/deliveries, etc.).</li>
        <li>Support budgeting and bookkeeping procedures.</li>
        <li>Assist in finding better ways to improve the financial standing of the organization.</li>
        <li>Create and update records and databases with personnel, financials, and other data.</li>
        <li>Track stocks of office supplies and place orders when necessary.</li>
        <li>Timeously submit reports and prepare presentations/proposals as assigned.</li>
        <li>File all documentation and keep files up to date and tidy.</li>
        <li>Assist colleagues whenever necessary.</li>
      </ul>
      <p>
        <strong>Supervision:</strong> Kegomoditswe
      </p>
      <p>
        <strong>Team:</strong> Kegomoditswe
      </p>
      <p>
        <strong>Current Projects:</strong> RISP, SharePoint, Onboarding
      </p>
    </div>
  ),
}
,
];

const JobRolesPreview = () => {
  const [jobRole, setJobRole] = useState(null);
  const { roleId } = useParams();

  useEffect(() => {
    const filteredJobRole = jobRolesData.find(
      (role) => role.id === parseInt(roleId)
    );
    setJobRole(filteredJobRole || null);
  }, [roleId]);

  return (
    jobRole && (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "top",
              }}
            >
              <Card className="procedure-card">
                <CardBody>
                  <CardTitle tag="h5">{jobRole.title()}</CardTitle>
                  <CardText>{jobRole.description()}</CardText>
                  <div className="enroll-options">
                    <Link to="../admin/roles">
                      <Button color="primary">Close</Button>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default JobRolesPreview;
