import React, { useState, useEffect } from "react";
import CourseContent from "./CourseContent";
import LeftPanel from "./LeftPanel";
import "./Course.css";
import MyEditor from "./CourseCreator";
import { useParams } from "react-router-dom";

const Course = () => {
  const { courseId } = useParams();
  const [course,setCourse]=useState();
  const [currentLesson, setCurrentLesson] = useState({lessonNumber:"0",lessonTitle: "Lesson Title",lessonDescription: "Lesson description"});
  const [lessons, setLessons] = useState([]);


     // Send both lesson content and course details to the server
     fetch(`http://localhost/getcourse.php?courseId=${courseId}`)
      .then(response => response.json())
      .then(data => {
        setCourse(data);
      })
      .catch(error => {
        console.error('Error saving content:', error);
        // Handle the error as needed
      });


  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await fetch(`http://localhost/getlessons.php?courseId=${courseId}`);
        if (!response.ok) {
          throw new Error(`Error fetching lessons: ${response.statusText}`);
        }
        const lessonsData = await response.json();
        setLessons(lessonsData);
        setCurrentLesson(lessons[0]);
      } catch (error) {
        console.error("Error fetching lessons:", error);
        // Handle the error as needed
      }
    };

    fetchLessons();
  }, [courseId]);

  const handleLessonChange = (selectedLesson) => {
    setCurrentLesson(selectedLesson);
  };

  const previousLesson = lessons[currentLesson.index - 1];
  const nextLesson = lessons[currentLesson.index + 1];

  return (
    <div className="course-dashboard">
      <LeftPanel course="Course Name" lessons={lessons} onLessonChange={handleLessonChange} />
      <div className="course-content">
        <CourseContent onLessonChange={handleLessonChange} lessons={lessons} lesson={currentLesson} />
        <MyEditor lesson={currentLesson} setLesson={setCurrentLesson} course={course} />
      </div>
    </div>
  );
};

export default Course;
