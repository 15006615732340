import React, { useState } from "react";
import "./companydocs.css";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';

const CompanyDocs = () => {
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const navigate = useNavigate();

  const documents = {
    "HR Documents": [
      { id: 1, title: "HR Document 1", pdfLink: "../../assets/docs/Galattica Package Fee.pdf" },
      { id: 2, title: "HR Document 2", pdfLink: "/path/to/HR_Document_2.pdf" },
      // Add more HR documents as needed
    ],
    "Job Cards": [
      { id: 3, title: "Job Card 1", pdfLink: "/path/to/Job_Card_1.pdf" },
      { id: 4, title: "Job Card 2", pdfLink: "/path/to/Job_Card_2.pdf" },
      // Add more Job Card documents as needed
    ],
    Processes: [
      { id: 5, title: "Process 1", pdfLink: "/path/to/Process_1.pdf" },
      { id: 6, title: "Process 2", pdfLink: "/path/to/Process_2.pdf" },
      // Add more Process documents as needed
    ],
  };

  const handleDocumentTypeClick = (documentType) => {
    setSelectedDocumentType(documentType);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/build/pdf.worker.js`;

  return (
    <div className="compdocs">
      <div className="text-center">
        <h1>Company Documents</h1>
      </div>
      <div>
        <p>This is all the important company documents</p>
      </div>
      <div className="container-fluid">
        <div className="row">
          {Object.keys(documents).map((documentType) => (
            <div className="col-lg-4" key={documentType}>
              <Card className="document" id={documentType}>
                <CardBody>
                  <CardTitle tag="h5">{documentType}</CardTitle>
                  {selectedDocumentType === documentType && (
                    <Document>
                      {documents[documentType].map((document) => (
                        <Page key={document.id}>
                          <div>
                            <strong>{document.title}</strong>:
                            <a href={document.pdfLink} download> Download PDF</a>
                          </div>
                        </Page>
                      ))}
                    </Document>
                  )}
                  <Button
                    className="btn btn-success"
                    onClick={() => handleDocumentTypeClick(documentType)}
                  >
                    More
                  </Button>
                </CardBody>
              </Card>
            </div>
          ))}
        </div>
      </div>

      {/* Nested Routes for Document Preview */}
      <Routes>
        <Route
          path="/company-docs/:documentType"
          element={<div>Document Preview Placeholder</div>} 
        />
      </Routes>
    </div>
  );
};

export default CompanyDocs;
