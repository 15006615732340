import React from 'react';
import { Card, CardBody, CardTitle, CardText, Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';


const JobRoleCard = ({ role }) => {
  
  return (
    <Col md={6}>
      <Card className="job-role-card">
        <CardBody>
          <CardTitle tag="h2">{role.title}</CardTitle>
          <CardText>{role.description}</CardText>
          <Link to={`../roles-preview/${role.id}`}>
            <Button className="btn btn-success">
              More
            </Button>
          </Link>
        </CardBody>
      </Card>
    </Col>
  );
};

const JobRoles = ({ roles }) => {
  return (
    <div className="job-roles-list">
      <h1 className='text-center'>Job Roles</h1>
      <Row>
        {roles.map((role) => (
          <JobRoleCard key={role.id} role={role} />
        ))}
      </Row>
    </div>
  );
};

const jobRolesData = [
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develop and maintain software applications.',
  },
  {
    id: 2,
    title: 'Software Network Engineer',
    description: 'Design and implement network solutions for software systems.',
  },
  {
    id: 3,
    title: 'IT Support',
    description: 'Provide technical support for hardware and software issues.',
  },
  {
    id: 4,
    title: 'Business Admin',
    description: 'Handle administrative tasks and support business operations.',
  },
];

export default function JobRolesContainer() {
  return (
    <div className="content">
      <JobRoles roles={jobRolesData} />
    </div>
  );
}
