import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardImg, CardTitle, CardText, Button } from 'reactstrap';
import './course-preview.css';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const coursesData = [
  {
    id: 1,
    title: 'React Masterclass',
    description: 'Learn React from scratch to expert level',
    image: 'https://cdn1.careeraddict.com/uploads/article/60468/computer-science-course.jpg',
    enrollLink: 'https://example.com/enroll/react-masterclass',
    courseLink: 'https://example.com/courses/react-masterclass',
  },
  {
    id: 2,
    title: 'JavaScript Essentials',
    description: 'Master the fundamentals of JavaScript programming',
    image: 'https://cdn1.careeraddict.com/uploads/article/60468/computer-science-course.jpg',
    enrollLink: 'https://example.com/enroll/javascript-essentials',
    courseLink: 'https://example.com/courses/javascript-essentials',
  },
  {
    id: 3,
    title: 'HTML5 and CSS3 for Web Design',
    description: 'Create responsive and visually appealing websites',
    image: 'https://cdn1.careeraddict.com/uploads/article/60468/computer-science-course.jpg',
    enrollLink: 'https://example.com/enroll/html5-css3-web-design',
    courseLink: 'https://example.com/courses/html5-css3-web-design',
  },
];

const CoursePreview = () => {
  const [course, setCourse] = useState(null); // Initialize course state as null
  const { courseId } = useParams();

  useEffect(() => {
    const filteredCourse = coursesData.filter((_course) => _course.id === parseInt(courseId));
    if (filteredCourse.length > 0) {
      setCourse(filteredCourse[0]); // Set the course state to the found course
    }
  }, [courseId]); // Add courseId as a dependency to the useEffect hook

  return (
    course && ( // Render the course preview only if the course state is not null
      <div className='container'>
        <div className='row'>
            <div className='col-lg-12'>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
        <Card className="course-card">
        <CardImg top width="100%" src={course.image} alt={course.title} />
        <CardBody>
          <CardTitle tag="h5">{course.title}</CardTitle>
          <CardText>{course.description}</CardText>
          <div className="enroll-options">
          <Link to="https://www.freecodecamp.org/" target="_blank" rel="noopener noreferrer">
        <Button color='primary'>
          Enroll
        </Button>
      </Link>
      <Button color="outline-primary" href="https://www.freecodecamp.org/" target="_blank" rel="noopener noreferrer">
        Learn More
      </Button>
          </div>
        </CardBody>
      </Card>
      </div>
      </div>
      </div>
      </div>
    )
  );
};

export default CoursePreview;
