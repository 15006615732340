import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, CardBody, CardFooter, CardText, FormGroup, Form, Input, Row, Col, FormText } from "reactstrap";

function UserProfile() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const d = await localStorage.getItem('userData');
      setUserData(JSON.parse(JSON.parse(d))[0]); // Set the userData state here
    };

    getData();
  }, []); // Empty dependency array so it only runs once

  useEffect(() => {
    if (userData !== null) {

    }
  }, [userData]); // Watch for changes in userData

  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Company</label>
                        <Input
                          defaultValue="Galattica Technologies Pty(Ltd)"
                          disabled
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                      
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input disabled placeholder="mike@email.com" value={userData && userData.email} type="email" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          defaultValue= {userData && userData.name}
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          defaultValue={userData && userData.surname}
                          placeholder="Last Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          defaultValue={userData && userData.address}
                          placeholder="Home Address"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          defaultValue={userData && userData.city}
                          placeholder="City"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          defaultValue={userData && userData.country}
                          placeholder="Country"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input placeholder="ZIP Code" defaultValue={userData && userData.postal_code} type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Profile Image</label>
                        <Input type="file" accept="image/*" />
                        <FormText color="muted">
                          Upload a profile image (Accepted formats: jpg, png, gif)
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          cols="80"
                          defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-fill" color="primary" type="submit">
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
          <Card className="card-user">
          <CardBody>
            <CardText />
            <div className="author">
              <div className="block block-one" />
              <div className="block block-two" />
              <div className="block block-three" />
              <div className="block block-four" />
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img
                  alt="..." 
                  className="avatar"
                  src={userData !== null ? userData.profilepic:""} // Assuming userData has an 'avatar' property
                />
                <h5 className="title">{userData && userData.name}</h5> {/* Assuming userData has a 'name' property */}
              </a>
              <p className="description">{userData && userData.surname}</p> {/* Assuming userData has a 'role' property */}
            </div>
            <div className="card-description">
              <p>{userData && userData.surname}</p>
            </div>
          </CardBody>
          <CardFooter>
            <div className="button-container">
              <Button className="btn-icon btn-round" color="facebook">
                <i className="fab fa-facebook" />
              </Button>
              <Button className="btn-icon btn-round" color="twitter">
                <i className="fab fa-twitter" />
              </Button>
              <Button className="btn-icon btn-round" color="google">
                <i className="fab fa-google-plus" />
              </Button>
            </div>
          </CardFooter>
        </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
