import React,{useRef} from 'react';
import {Button,Card,CardHeader,CardBody,CardFooter,CardText,FormGroup,Form,Input,Row,Col} from "reactstrap";
import './CompanyStructure.css';
import km from '../assets/img/LinkedinBannerimg.jpg';

const profiles = {
    georgeSindane: {
      firstName: 'George',
      lastName: 'Sindane',
      position: 'Managing Director',
      description: 'Takes care of the operations of the business',
      avatar: 'https://pbs.twimg.com/profile_images/1771195591/image_400x400.jpg',
    },
    victorMolapo: {
      firstName: 'Victor',
      lastName: 'Sepeng',
      position: 'Team Leader',
      description: 'Specializes in Cybersecurity',
      avatar: 'https://media.licdn.com/dms/image/C5603AQGeTpbpOJAnKw/profile-displayphoto-shrink_800_800/0/1560256606301?e=2147483647&v=beta&t=ibelbAfspmIxMrFLFMpvu2YqWWirE2qlUwGc4C0A-zk',
    },
 
    kegoMolefe: {
      firstName: 'Kegomoditswe',
      lastName: 'Sehlatse',
      position: 'Business Admin',
      description: 'Specializes in microsoft',
      avatar: 'https://media.licdn.com/dms/image/D4E03AQH-qwdVNX1CYg/profile-displayphoto-shrink_800_800/0/1691947275333?e=1704931200&v=beta&t=ExlFnw5nMtTv_YvPHCgKjURTxL0w-H-ZXzM0_0HeFQ0',
    }, 
    khayaM: {
      firstName: 'Khaya',
      lastName: 'Manyathi',
      position: 'Software Network Eng.',
      description: 'REACTjs, HTML5, CSS, Java, Py',
      avatar: `${ km }`,
    }
  };
  

const CompanyStructure = () => (
  <div className="company-structure">
    <h2 className='text-center'>Galattica Team</h2>

    <div className="employee-cards" >
        <div className='container-fluid'>
            <div className='row justify-content-center'>
      {Object.keys(profiles).map((profileName) => (
        <div className='col-lg-3'>
<Card className="card-user">
<CardBody>
  <CardText />
  <div className="author">
    <div className="block block-one" />
    <div className="block block-two" />
    <div className="block block-three" />
    <div className="block block-four" />
    <a href="#pablo" onClick={(e) => e.preventDefault()}>
      <img
        alt={profileName.firstName}
        className="avatar"
        src={profiles[profileName].avatar}
      />
      <h5 className="title">{profiles[profileName].firstName}{" "}{profiles[profileName].lastName}</h5>
    </a>
    <p className="description">{profiles[profileName].position}</p>
  </div>
  <div className="card-description text-center">
  <truncateSringWithReadMore  />
  </div>
</CardBody>
<CardFooter>
  <div className="button-container">
    <Button className="btn-icon btn-round" color="facebook">
      <i className="fab fa-facebook" />
    </Button>
    <Button className="btn-icon btn-round" color="twitter">
      <i className="fab fa-twitter" />
    </Button>
    <Button className="btn-icon btn-round" color="google">
      <i className="fab fa-google-plus" />
    </Button>
  </div>
</CardFooter>
</Card>
</div>

      ))}
    </div>
    </div>
</div>
  </div>
);

export default CompanyStructure;
