import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import "./procedures-preview.css";
import Policies from "./Policies";

const proceduresData = [
  {
    id: 1,
    title: () => <h2>Culture</h2>,
    description: () => (
      <div className="procContent">
        <h3>Respect and Fairness at Galattica</h3>
        <p>
          We believe that respect and fairness are the cornerstones of a
          successful and sustainable business. These values guide our daily
          operations and are deeply ingrained in our corporate culture.
        </p>

        <h4>1. Respect for Employees:</h4>
        <ul>
          <li>
            Providing equal opportunities for career advancement and
            professional development.
          </li>
          <li>
            Maintaining an inclusive and diverse workforce that celebrates
            differences.
          </li>
          <li>Ensuring fair compensation and benefits packages.</li>
          <li>
            Encouraging open communication and a supportive work environment.
          </li>
        </ul>

        <h4>2. Respect for Customers:</h4>
        <ul>
          <li>
            Offering high-quality products and services that meet their needs.
          </li>
          <li>
            Listening to their feedback and continuously improving our
            offerings.
          </li>
          <li>Honouring our commitments and delivering on promises.</li>
          <li>Resolving issues promptly and fairly.</li>
        </ul>

        <h4>3. Fairness in Business Practices:</h4>
        <ul>
          <li>Upholding integrity in all our transactions and dealings.</li>
          <li>
            Avoiding discriminatory practices and promoting equal opportunities.
          </li>
          <li>
            Ensuring fair competition and compliance with all applicable laws
            and regulations.
          </li>
          <li>
            Supporting fair trade and sustainable sourcing whenever possible.
          </li>
        </ul>

        <h4>4. Community and Social Responsibility:</h4>
        <ul>
          <li>Supporting local initiatives and charitable organizations.</li>
          <li>
            Minimizing our environmental impact through sustainable practices.
          </li>
          <li>Engaging in philanthropic efforts that align with our values.</li>
          <li>
            Promoting social responsibility and ethical behaviour among our
            employees.
          </li>
        </ul>

        <h4>5. Continuous Improvement:</h4>
        <ul>
          <li>
            Conducting regular training and awareness programs for our
            employees.
          </li>
          <li>
            Soliciting feedback from stakeholders to identify areas for
            enhancement.
          </li>
          <li>
            Adapting our policies and practices to reflect evolving societal
            norms and expectations.
          </li>
        </ul>

        <p>
          At Galattica, our commitment to respect and fairness is not just a
          moral obligation but a strategic advantage. It strengthens our
          relationships with employees, customers, partners, and the communities
          we serve. By valuing respect and fairness as a business, we create a
          sustainable and ethical framework that not only benefits our bottom
          line but also contributes positively to society as a whole. We are
          proud to uphold these values, and they will continue to guide us in
          our mission to make a positive impact in the world.
        </p>
        <h4>Our Belief:</h4>
        <p>
          At Galattica, our conviction is that diversity is our greatest asset.
          We are unwavering in our mission to build and nurture an environment
          where every member feels recognized, respected, and empowered to
          excel.
        </p>

        <h4>Diversity:</h4>
        <p>
          We value the broad spectrum of individual attributes, including but
          not limited to ethnicity, faith, gender identity, age, physical
          abilities, economic background, culture, and sexual orientation. We
          constantly strive to enhance the diversity within our team, ensuring a
          space where varied viewpoints and experiences are both acknowledged
          and cherished.
        </p>

        <h4>Inclusion:</h4>
        <p>
          Our aim is to forge a workspace where everyone has an equal footing to
          employ their skills and take part in all company pursuits
          unobstructed. We are devoted to adapting our professional setting to
          be inclusive and to dismantle any biases present in our recruitment
          and advancement processes.
        </p>

        <h4>Fairness:</h4>
        <p>
          We champion providing equal chances to every employee and guarantee
          that decisions related to employment, be it recruitment, promotions,
          pay, or training, are executed without prejudice. We are proactive in
          initiating steps to ensure a just distribution of opportunities,
          rewards, and outcomes for all our team members.
        </p>

        <h4>Measures and Endeavors:</h4>
        <ul>
          <li>
            <strong>Recruitment:</strong> Utilizing unbiased hiring
            methodologies, introducing anonymous applications, diverse interview
            panels, and proactive engagement with communities that are
            underrepresented.
          </li>
          <li>
            <strong>Supportive Networks:</strong> Establishing and endorsing
            networks within the company that caters to different groups,
            promoting a culture of mutual support and understanding.
          </li>
          <li>
            <strong>Inclusive Policies:</strong> Evaluating and adjusting our
            company policies and perks to ensure they accommodate the varied
            needs of our diverse team.
          </li>
          <li>
            <strong>Engaged Leadership:</strong> Our leadership remains deeply
            invested in, and advocates for, our inclusivity and diversity
            measures.
          </li>
          <li>
            <strong>Oversight:</strong> We routinely refine our strategies
            related to diversity and inclusion to guarantee they resonate with
            our organizational ethos and objectives.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 2,
    title: () => <h2>Procedures</h2>,
    description: () => (
      <div className="procContent">
        <h3>Upholding Our Fundamental Principles at Galattica</h3>
        <p>
          At Galattica, we are dedicated to fostering a culture defined by our
          core values. These principles serve as the foundation of our
          organization, guiding our actions and driving our success.
        </p>

        <h4>1. Availability:</h4>
        <p>
          Availability is the key to our commitment to customer satisfaction.
          Our clients can rely on us to be there when they need us the most,
          ensuring accessibility for support, consultations, or inquiries. Our
          24/7 customer support reinforces our dedication to being available
          whenever our clients require assistance.
        </p>

        <h4>2. Productivity:</h4>
        <p>
          Productivity is a cornerstone of our dedication to delivering
          high-quality service. Equipped with the latest tools and technologies,
          our teams strive for efficiency and effectiveness. We continuously
          seek innovative solutions to enhance productivity, ensuring timely
          results while upholding the highest standards of excellence.
        </p>

        <h4>3. Professionalism:</h4>
        <p>
          Professionalism is fundamental to our interactions within our team and
          with our clients. Upholding the highest ethical standards, our
          employees treat every client, colleague, and partner with respect and
          courtesy. Transparency, honesty, and integrity define our commitment
          to professionalism.
        </p>

        <h4>4. Punctuality:</h4>
        <p>
          Punctuality is a hallmark of our commitment to delivering on promises.
          Valuing our clients' time, we adhere to strict schedules for project
          milestones, appointments, and deliverables. Our punctuality
          demonstrates reliability and dependability, setting us apart as a
          trustworthy partner.
        </p>

        <p>
          In conclusion, at Galattica, our unwavering commitment to
          Availability, Productivity, Professionalism, and Punctuality underpins
          our mission to provide exceptional services and build lasting
          relationships with our clients. These principles guide our everyday
          actions and decisions, empowering us to deliver outstanding results
          and maintain the highest standards of excellence in everything we do.
          As we move forward, we will continue to embrace and champion these
          principles, ensuring that they remain at the heart of our company's
          culture and success.
        </p>
        <h4>
          What are the expectations for communication and collaboration within
          Galattica and its team and across the departments?
        </h4>

        <ul>
          <li>
            <strong>Primary Business Platform:</strong> Email will continue to
            serve as the primary platform for all formal business communication.
          </li>
          <li>
            <strong>Company Calls:</strong> All employees are required to
            install Zoiper for company calls.
          </li>
          <li>
            <strong>Prompt Communication:</strong> For timely responses, the use
            of WhatsApp and telephone communication is encouraged, as they have
            proven to yield quicker results.
          </li>
          <li>
            <strong>ServiceDesk Platform:</strong> FreshDesk is the designated
            ServiceDesk platform for the company. All employees are expected to
            have the FreshDesk App on their mobile phones.
          </li>
          <li>
            <strong>Task Management:</strong> Trello provides a centralized view
            for all employees to manage their tasks. This enables individuals to
            prioritize their tasks for the week, with a weekly review during the
            team meeting.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 3,
    title: () => <h2>Policies</h2>,
    description: () => (
      <div>
        <Policies />
      </div>
    ),
  },
];

const ProceduresPreview = () => {
  const [procedure, setProcedure] = useState(null);
  const { procedureId } = useParams();

  useEffect(() => {
    const filteredProcedure = proceduresData.find(
      (_procedure) => _procedure.id === parseInt(procedureId)
    );
    setProcedure(filteredProcedure || null);
  }, [procedureId]);

  return (
    procedure && (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "top",
              }}
            >
              <Card className="procedure-card">
                <CardBody>
                  <CardTitle tag="h5">{procedure.title()}</CardTitle>
                  <CardText>{procedure.description()}</CardText>
                  <div className="enroll-options">
                    <Link to="../admin/procedures">
                      <Button color="primary">Close</Button>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ProceduresPreview;
