import React from "react";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import "./Policies.css";

const PolicyCard = ({ title, description }) => (
  <div className="col-md-12">
    <Card className="policy-card">
      <CardBody>
        <CardTitle as="h3">{title}</CardTitle>
        <CardText>{description()}</CardText>
      </CardBody>
    </Card>
  </div>
);

const Policies = () => {
  const privacyPoliciesDescription = () => (
    <div>
      <h4>Introduction</h4>
      <p>
        Privacy policies are legal documents or statements that organizations, websites, or mobile apps use to inform individuals about how they collect, use, disclose, and protect personal information.
      </p>
      <h4>Scope</h4>
      <p>
        This section defines the scope of the privacy policy, specifying which services or activities it covers. For example, it might cover website usage, mobile app usage, or interactions with customer support.
      </p>
      <h4>Information Collected</h4>
      <p>
        Here, the policy outlines the types of information collected. This can include personal information (e.g., name, email, phone number), non-personal information (e.g., browser type, IP address), and potentially sensitive data (e.g., financial information, medical records).
      </p>
      <h4>How Information is Collected</h4>
      <p>
        This section explains the methods used to collect information. Common methods include cookies, web forms, registration processes, and user interactions.
      </p>
      <h4>Purpose of Data Processing</h4>
      <p>
        Privacy policies should clarify why the organization collects and processes personal data. This may include reasons such as providing services, improving user experience, marketing, and complying with legal requirements.
      </p>
      <h4>Data Usage</h4>
      <p>
        This section describes how the collected data is used. It might include information on data analytics, personalization of services, and sharing data with third parties for specific purposes.
      </p>
      <h4>Data Sharing</h4>
      <p>
        Organizations should specify whether they share personal data with third parties and, if so, with whom and for what purposes. This can include sharing with service providers, business partners, advertisers, or law enforcement agencies.
      </p>
      <h4>User Rights</h4>
      <p>
        Privacy policies often outline the rights users have regarding their data. This can include the right to access, correct, or delete their data, as well as how to exercise these rights.
      </p>
      <h4>Data Security</h4>
      <p>
        This section discusses the measures taken to protect the data from unauthorized access, breaches, or other security threats. It may also mention encryption, access controls, and security audits.
      </p>
      <h4>Data Retention</h4>
      <p>
        Organizations should specify how long they retain user data. Different types of data may have different retention periods.
      </p>
      <h4>Cookies and Tracking Technologies</h4>
      <p>
        If cookies or similar tracking technologies are used, the policy should explain their purpose and provide information on how users can manage or disable them.
      </p>
      <h4>Policy Updates</h4>
      <p>
        Organizations should inform users about their ability to update the privacy policy and when changes are made. Users should be encouraged to review the policy regularly.
      </p>
      <h4>Contact Information</h4>
      <p>
        Privacy policies typically include contact information for users to reach out with questions or concerns regarding their data and privacy.
      </p>
      <h4>Legal Compliance</h4>
      <p>
        Organizations may include a section explaining their compliance with relevant privacy laws and regulations, such as the General Data Protection Regulation (GDPR) or the California Consumer Privacy Act (CCPA).
      </p>
      <h4>Opt-Out Options</h4>
      <p>
        For marketing communications, privacy policies should include information on how users can opt out of receiving promotional messages.
      </p>
      <h4>Consent</h4>
      <p>
        Some policies explain the process of obtaining user consent for data collection and processing.
      </p>
      <p>
        It's essential for organizations to make their privacy policies clear, concise, and easy to understand for users. Users should be able to access the policy easily and consent to it before providing their personal information. Failure to comply with privacy laws and regulations or misleading users about data practices can lead to legal consequences and damage to an organization's reputation.
      </p>
    </div>
  );

  const diversityInclusionDescription = () => (
    <div>
      <h4>Diversity</h4>
      <p>
        Diversity encompasses the range of differences among individuals. It includes characteristics such as race, ethnicity, gender, age, sexual orientation, religion, disability, socioeconomic status, education, and more.
      </p><br></br>
      <h4>Inclusion</h4>
      <p>
        Inclusion is the practice of ensuring that all individuals feel welcomed, respected, and valued within an organization or community. It involves creating a culture where diverse individuals can fully participate, contribute, and thrive.
      </p>
      <p>
        Inclusion goes beyond mere representation and aims to create an environment where everyone has a sense of belonging and is empowered to reach their full potential.
      </p>
      <p>
        Data security and privacy
      </p>
      <p>
        Data security and privacy are critical aspects of the modern digital world, encompassing practices and measures to protect sensitive information from unauthorized access, breaches, and misuse. While related, they have distinct focuses:
      </p><br></br>
      <h4>Data Security</h4>
      <p>
        Data security refers to the protection of data from various threats and vulnerabilities. It involves safeguarding data in storage, transit, and processing to ensure its confidentiality, integrity, and availability. Key components of data security include:
      </p><br></br>
      <h4>Encryption</h4>
      <p>
        The process of converting data into a code to prevent unauthorized access. This is often used for data in transit (e.g., during online transactions) and data at rest (e.g., on hard drives).
      </p><br></br>
      <h4>Access Controls</h4>
      <p>
        Implementing measures to control who can access data, what they can do with it, and when they can access it. This includes user authentication, authorization, and role-based access control (RBAC).
      </p><br></br>
      <h4>Data Backups</h4>
      <p>
        Regularly backing up data to ensure its availability in case of data loss due to hardware failures, cyberattacks, or other disasters.
      </p><br></br>
      <h4>Patch Management</h4>
      <p>
        Keeping software, operating systems, and applications up to date with the latest security patches to address known vulnerabilities.
      </p><br></br>
      <h4>Data Privacy</h4>
      <p>
        Data privacy, on the other hand, focuses on how personal information is collected, used, shared, and protected. It involves ensuring that individuals have control over their personal data and that organizations handling this data do so in compliance with relevant laws and regulations. Key elements of data privacy include:
      </p><br></br>
      <h4>Consent</h4>
      <p>
        Obtaining informed and explicit consent from individuals before collecting their personal data and explaining how it will be used.
      </p><br></br>
      <h4>Data Minimization</h4>
      <p>
        Collecting and retaining only the data that is necessary for the intended purpose, avoiding excessive or unnecessary data collection.
      </p><br></br>
      <h4>Data Access and Portability</h4>
      <p>
        Allowing individuals to access their own data, request corrections, and, in some cases, transfer their data to other service providers.
      </p><br></br>
      <h4>Data Protection Laws</h4>
      <p>
        Complying with data protection regulations such as the General Data Protection Regulation (GDPR) in the European Union, the California Consumer Privacy Act (CCPA), and others that impose legal requirements on data handling.
      </p><br></br>
      <h4>Data Privacy Policies</h4>
      <p>
        Developing and publishing privacy policies that inform individuals about how their data is processed and protected.
      </p><br></br>
      <h4>Data Breach Notification</h4>
      <p>
        Promptly notifying affected individuals and regulatory authorities in the event of a data breach that could compromise personal information.
      </p><br></br>
      <h4>Privacy by Design</h4>
      <p>
        Integrating privacy considerations into the design and development of products and services from the outset.
      </p>
      <p>
        Data security and data privacy are closely related because strong data security measures are essential to safeguarding the privacy of individuals. A breach in data security can lead to unauthorized access and exposure of personal data, potentially resulting in privacy violations and legal consequences.
      </p>
      <p>
        Organizations and individuals must prioritize both data security and data privacy to protect sensitive information and maintain trust in an increasingly data-driven world. This involves implementing robust security measures while respecting privacy rights and adhering to relevant laws and regulations.
      </p><br></br>
      <h4>Galattica is POPI compliant, therefore all private information is treated with confidentiality never sold to 3rd parties.</h4>
    </div>
  );
    return (
      <div>
        <h2>Policies at GALATTICA</h2>
  
        <div className="row">
          <div className="col-md-12">
            <Card className="policy-card">
              <CardBody>
                <CardTitle><h3>Privacy Policies</h3></CardTitle>
                <CardText>{privacyPoliciesDescription()}</CardText>
              </CardBody>
            </Card>
          </div>
        </div>
  
        <div className="row">
          <div className="col-md-12">
            <Card className="policy-card">
              <CardBody>
                <CardTitle><h3>Diversity and Inclusion</h3></CardTitle>
                <CardText>{diversityInclusionDescription()}</CardText>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  };
  
  export default Policies;

