import React from 'react';
import { Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import './AboutGalattica.css';
import '../assets/css/black-dashboard-react.css';
const AboutGalattica = () => (
  <Card className="about-galattica">
    <CardBody>
      <CardTitle tag="h2">About Galattica</CardTitle>

      <iframe className='vidContainer'
      style={{width:"600px",height:"400px"}}
        src="https://www.youtube.com/embed/ILQeXZTOpkw"
        title="History of the Internet 2021 Documentary"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
<br/>
      <CardText>
        Galattica is a South African IT company that specializes in providing<br/>
        networking, cybersecurity, consulting, and content creation services to<br/>
        businesses of all sizes. We are committed to helping our clients improve<br/>
        their efficiency, security, and overall IT capabilities.
      </CardText>
<br/>
      <CardTitle tag="h2">What is Galattica?</CardTitle>
      <CardText>
        Galattica is a team of highly skilled and experienced IT professionals<br/>
        who are passionate about helping businesses succeed. We are experts in<br/>
        networking, cybersecurity, and e-learning, and we are committed to<br/>
        providing our clients with the best possible solutions.
      </CardText>
      <br/>
      <CardTitle tag="h2">How and why was Galattica started?</CardTitle>
      <CardText>
        Galattica was founded by George Sindane, who has a long and successful<br/>
        career in the IT industry. George is passionate about training people<br/>
        and growing businesses, and he believes that Galattica can make a<br/>
        positive impact on the world by helping businesses succeed.
      </CardText>
      <br/>
      <CardTitle tag="h2">What is the company's end goal?</CardTitle>
      <CardText>
        Galattica's goal is to have a positive impact on the world by edifying,<br/>
        enhancing, and empowering businesses and communities. We want to help<br/>
        businesses succeed so that they can create jobs, contribute to the<br/>
        economy, and make a positive impact on the world.
      </CardText>
      <br/>
      <CardTitle tag="h2">Services offered?</CardTitle>
      <ul>
        <li>Networking and cybersecurity</li>
        <li>Consulting</li>
        <li>Content creation and e-learning</li>
      </ul>

      <CardText>
        We offer a wide range of IT services to businesses of all sizes. Our<br/>
        team of experts is here to help you find the solutions you need to<br/>
        succeed.
      </CardText>
    </CardBody>
    
  </Card>
);

export default AboutGalattica;
