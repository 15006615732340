import React, { useState } from "react";
import "./SignUp.css";
import { serverPath } from "./variables";
import { Link, useNavigate } from "react-router-dom";

const SignupForm = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confpass, setConfPass] = useState("");
  const [about, setAbout] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const validatePassword = async (e) => {
    e.preventDefault();

    if (emailExists) {
      setErrorMessage("Email already exists");
      return false;
    }

    if (pass !== confpass) {
      setErrorMessage("Passwords do not match");
      return false;
    }

    // Fetch call to signup.php
    try {
      const response = await fetch(serverPath + "/Onboarding/api/signup.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          surname,
          email,
          password: pass,
          about,
          profile_picture: profilePic,
          address,
          city,
          country,
          postal_code: postalCode,
        }),
      });

      const data = await response.json();

      if (data.message === "success") {
        setSuccessMessage("Form submitted successfully!");
        navigate("/login");
      } else if (data.message === "emailexists") {
        setErrorMessage("Already registered. Please log in");
        navigate("/login");
      } else {
        alert("Signup failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEmailChange = async (e) => {
    const theEmail = e.target.value;

    try {
      const emailExists = await checkIfEmailExists(theEmail);
      setEmailExists(emailExists === "emailexists" ? true : false);
    } catch (error) {
      console.error("Error:", error);
      setEmailExists(false);
    }
  };

  const checkIfEmailExists = async (email) => {
    try {
      const response = await fetch(serverPath + "/Onboarding/api/api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "checkIfEmailExists",
          email: email,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Assuming your response from the server is a boolean
      alert(data.message);
      return data.message;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };
  const clearError = () => {
    setErrorMessage("");
  };

  const clearSuccess = () => {
    setSuccessMessage("");
  };

  return (
    <div className="main-containerS">
      <main>
        <h3 className="loginhead">Fill in your details to register</h3>
        <section className="signin-box">
          <form onSubmit={validatePassword}>
            <div className="spaces">
              <label htmlFor="name">Name(s): </label>
              <input
                className="input-box"
                type="text"
                name="name"
                placeholder="Please input your name(s)"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="spaces">
              <label htmlFor="surname">Surname: </label>
              <input
                className="input-box"
                type="text"
                name="surname"
                placeholder="Please input your surname"
                required
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            <div className="spaces">
              <label htmlFor="email">Email Address: </label>
              <input
                id="eml"
                className="input-box"
                type="email"
                name="email"
                placeholder="e.g user123@gmail.com"
                onClick={clearError}
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                  handleEmailChange(e);
                }}
              />
            </div>
            {/* <center>
              <font style={{ color: "red" }} id="mailexistmsg">
                {emailExists && "Email already exists"}
              </font>
            </center> */}
            <div className="spaces">
              <label htmlFor="password">Password: </label>
              <input
                className="input-box"
                id="pass"
                type="password"
                name="password"
                placeholder="Create a password"
                onClick={clearError}
                required
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <div className="spaces">
              <label htmlFor="confirm password">Confirm Password: </label>
              <input
                className="input-box"
                id="confpass"
                type="password"
                name="Confirm Password"
                placeholder="Confirm your password"
                onClick={clearError}
                required
                onChange={(e) => setConfPass(e.target.value)}
              />
            </div>
            <div className="spaces">
              <label htmlFor="about_me">About Me:</label>
              <textarea
                className="input-box"
                id="about_me"
                name="about_me"
                rows="4"
                placeholder="Tell us more about yourself. Share your professional journey, skills, and aspirations. Let your personality shine through while maintaining a professional tone."
                onChange={(e) => setAbout(e.target.value)}
              ></textarea>
            </div>
            <div className="spaces">
              <label htmlFor="profile_picture">Profile Picture:</label>
              <input
                className="input-box"
                id="profile_picture"
                type="file"
                name="profile_picture"
                accept="image/jpeg"
                onChange={(e) => setProfilePic(e.target.value)}
              />
            </div>
            <div className="spaces">
              <label htmlFor="address">Address:</label>
              <input
                className="input-box"
                id="address"
                type="text"
                name="address"
                placeholder="Please input your address"
                required
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="spaces">
              <label htmlFor="city">City:</label>
              <input
                className="input-box"
                id="city"
                type="text"
                name="city"
                placeholder="Please input your city"
                required
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="spaces">
              <label htmlFor="country">Country:</label>
              <input
                className="input-box"
                id="country"
                type="text"
                name="country"
                placeholder="Please input your country"
                required
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <div className="spaces">
              <label htmlFor="postal_code">Postal Code:</label>
              <input
                className="input-box"
                id="postal_code"
                type="text"
                name="postal_code"
                placeholder="Please input your postal code"
                required
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
            <center>
              <font style={{ color: "red" }} id="passmessage"></font>
            </center>
            <div className="bspaces">
              <button type="submit">Submit</button>
            </div>
          </form>
          {errorMessage && (
            <p style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</p>
          )}
          {successMessage && (
            <p style={{ color: "green", fontWeight: "bold" }}>
              {successMessage}
            </p>
          )}
          <p>
            Already Registered? <Link to="/login">Log In</Link>
          </p>
        </section>
      </main>
    </div>
  );
};

export default SignupForm;
