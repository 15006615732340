import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Row,
  Col,
} from "reactstrap";
import "./landing.css";
import { useNavigate } from "react-router-dom";
import team from "../assets/img/team.jpg";
import training from "../assets/img/training.jpg";
import courses from "../assets/img/courses.jpg";
import policies from "../assets/img/policies.jpg";
import performance from "../assets/img/performance.jpg";
import roles from "../assets/img/roles.jpg";



const cardStyle = {
  height: "90%",
  margin: "0px",
  marginTop: "15px",
};

const imageStyle = {
  width: "350px",
  height: "150px",
  objectFit: "cover",
  margin: "0px",
};

const Landing = () => {

  const navigate = useNavigate();


  const handleExploreClick = (path) => {
    navigate(path);
  };

  return (
    <div className="landing content">
      <h3 className="text-center" style={{ marginTop: "5px" }}>
        Welcome on board🚀 Click explore to begin.
      </h3>
      <Row>
        {/* First Row */}
        <Col md={4}>
          <Card style={cardStyle}>
            <CardBody className="text-center">
              <CardTitle>Company structure (team)</CardTitle>
              <img
                src={team}
                alt="Team"
                className="img-fluid"
                style={imageStyle}
              />

              <Button
                style={{ marginTop: "15px" }}
                color="primary"
                className="d-block mx-auto"
                onClick={() => handleExploreClick("/admin/structure")}
              >
                Explore
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={cardStyle}>
            <CardBody className="text-center">
              <CardTitle>Training and Development</CardTitle>
              <img
                src={training}
                alt="Training"
                className="img-fluid"
                style={imageStyle}
              />

              <Button
                style={{ marginTop: "15px" }}
                color="primary"
                className="d-block mx-auto"
                onClick={() => handleExploreClick("/admin/training")}
              >
                Explore
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={cardStyle}>
            <CardBody className="text-center">
              <CardTitle>Courses</CardTitle>
              <img
                src={courses}
                alt="Courses"
                className="img-fluid"
                style={imageStyle}
              />

              <Button
                style={{ marginTop: "15px" }}
                color="primary"
                className="d-block mx-auto"
                onClick={() => handleExploreClick("/admin/courses")}
              >
                Explore
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* Second Row */}
        <Col md={4}>
          <Card style={cardStyle}>
            <CardBody className="text-center">
              <CardTitle>Procedures, Culture, and Policies</CardTitle>
              <img
                src={policies}
                alt="Policies"
                className="img-fluid"
                style={imageStyle}
              />

              <Button
                style={{ marginTop: "15px" }}
                color="primary"
                className="d-block mx-auto"
                onClick={() => handleExploreClick("/admin/procedures")}
              >
                Explore
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={cardStyle}>
            <CardBody className="text-center">
              <CardTitle>Performance Evaluation</CardTitle>
              <img
                src={performance}
                alt="Performance"
                className="img-fluid"
                style={imageStyle}
              />

              <Button
                style={{ marginTop: "15px" }}
                color="primary"
                className="d-block mx-auto"
                onClick={() => handleExploreClick("/admin/performance")}
              >
                Explore
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={cardStyle}>
            <CardBody className="text-center">
              <CardTitle>Roles and Responsibilities</CardTitle>
              <img
                src={roles}
                alt="Roles"
                className="img-fluid"
                style={imageStyle}
              />

              <Button
                style={{ marginTop: "15px" }}
                color="primary"
                className="d-block mx-auto"
                onClick={() => handleExploreClick("/admin/roles")}
              >
                Explore
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Landing;
