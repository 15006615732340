import React from "react";
import "./Roles.css";

const Training = () => {
  
  return (
    <div className="training">
      <h1 className="text-center">Training and Development:</h1>
      <table className="training-table">
        <thead>
          <tr>
            <th>Description</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Training programs or resources</td>
            <td>
              Sage Accounting, Financial Based Courses and 0ffice 365. Further
              courses will be discussed in the performance appraisal
            </td>
          </tr>
          <tr>
            <td>Career advancement opportunities</td>
            <td>Financial Controller.</td>
          </tr>

          <tr>
            <td>Compulsory or mandatory</td>
            <td>The training is compulsory for all employees. </td>
          </tr>
        </tbody>
      </table>
      <br></br>

      <h1 className="text-center">Company Events and Social Activities:</h1>
      <table className="events-table">
        <thead>
          <tr>
            <th>Month</th>
            <th>Event</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>January</td>
            <td>New Year's Day - January 1</td>
          </tr>
          <tr>
            <td>February</td>
            <td>Human Rights Day - March 21</td>
          </tr>
          <tr>
            <td>March</td>
            <td>
              Data Privacy Day (January 28th): A day dedicated to raising
              awareness and promoting privacy and data protection best
              practices. <br />
              Safer Internet Day (February): An event that promotes safer and
              more responsible use of online technology and mobile devices,
              especially among children and young people.
            </td>
          </tr>
          <tr>
            <td>April</td>
            <td>
              Good Friday
              <br />
              Freedom Day - April 27
            </td>
          </tr>
          <tr>
            <td>May</td>
            <td>
              Family Day
              <br />
              Workers' Day - May 1<br />
              World Password Day (First Thursday of May): A day to promote good
              cybersecurity habits, particularly related to password strength
              and management.
            </td>
          </tr>
          <tr>
            <td>June</td>
            <td>
              Youth Day - June 16
              <br />
              Company Team Building & Strat Review Session
            </td>
          </tr>
          <tr>
            <td>July</td>
            <td>National Women's Day - August 9</td>
          </tr>
          <tr>
            <td>August</td>
            <td>Heritage Day - Sep 24</td>
          </tr>
          <tr>
            <td>October</td>
            <td>Cybersecurity Month</td>
          </tr>
          <tr>
            <td>December</td>
            <td>
              Year End Celebration
              <br />
              Company Strategy Session
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Training;
