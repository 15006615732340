import React, { createContext } from "react";
import backlight from './GALATTICA - LIGHT.png';
import backdark from './GALATTICA - DARK.png';
import '../assets/css/black-dashboard-react.css';

export const themes = {
  dark: {
    background: backdark,
    content: "",
  },
  light: {
    background: backlight,
    content: "white-content",
  },
};

export const ThemeContext = createContext({
  theme: themes.light,
  changeTheme: () => {},
});

// Custom component using the ThemeContext
export const ThemeImage = () => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <div>
      {/* Your other components */}
      <img src={theme.background} alt="Background Image" className='image-container' />
    </div>
  );
};
