import React, { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './editorstyle.css';

export default function MyEditor({ course, lesson, setLesson }) {
  const [editorData, setEditorData] = useState('');
  const [fileExists, setFileExists] = useState(true);
  const lessonTitleRef = useRef(null);
  const lessonDescriptionRef = useRef(null);

  useEffect(() => {
    if (lesson.lessonNumber !== 0) {
      setEditorData("");
      // Fetch content from the server when the component mounts
      fetch(`http://localhost/${course.courseId}/lesson${lesson.lessonNumber}_saved_content.txt`)
        .then(response => {
          if (response.ok) {
            return response.text();
          } else {
            setFileExists(false);
            return Promise.reject('File not found');
          }
        })
        .then(data => {
          setEditorData(data);
        })
        .catch(error => {
          console.error('Error fetching content:', error);
          // Handle the error as needed
        });
    }
  }, [course, lesson]);

  const handleLessonTitleChange = () => {
    setLesson({ ...lesson, lessonTitle: lessonTitleRef.current.value });
  };

  const handleLessonDescriptionChange = () => {
    setLesson({ ...lesson, lessonDescription: lessonDescriptionRef.current.value });
  };

  const handleSave = () => {
    // Combine lesson content and course details
    const requestData = {
      content: editorData,
      courseDetails: course,
      lessonDescription: lessonDescriptionRef.current.value,
      lessonTitle: lessonTitleRef.current.value,
    };

    if(lesson.lessonNumber == 0){
    // Send both lesson content and course details to the server
    fetch(`http://localhost/addnewlesson.php?courseId=${course.courseId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Save response:', data);
        // Handle the response from the server as needed
      })
      .catch(error => {
        console.error('Error saving content:', error);
        // Handle the error as needed
      });
    }
      else{
          // Send both lesson content and course details to the server
      fetch(`http://localhost/updatelesson.php?courseId=${course.courseId}&lessonNumber=${lesson.lessonNumber}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Save response:', data);
          // Handle the response from the server as needed
        })
        .catch(error => {
          console.error('Error saving content:', error);
          // Handle the error as needed
        });
      }

  };

  return (
    <div className="text-dark">
      <div>
        <h2><input onChange={handleLessonTitleChange} type="text" ref={lessonTitleRef} /></h2>
        <p><input onChange={handleLessonDescriptionChange} type="text" ref={lessonDescriptionRef} /></p>
      </div>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onReady={(editor) => {
          console.log('CKEditor React Component is ready to use!', editor);
        }}
        config={{
          placeholder: 'Start typing to create lesson content',
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
          console.log({ event, editor, data });
        }}
      />

      <div>
        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
}
