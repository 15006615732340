import React from "react";
import AboutGalattica from "./AboutGalattica";

const Introduction = ()=>{
       const token=localStorage.getItem('token');
  if (!token) {
    window.location.href='/login';
  }
 return(<div className="content">
    
        <AboutGalattica/>

 </div>)
};

export default Introduction;