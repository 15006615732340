import React, { useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";

import { serverPath } from "./variables";

const Login = () => {
  const [rememberCredentials, setRememberCredentials] = useState(true);
  const storedUsername = localStorage.getItem("username") || "";
  const storedPassword = localStorage.getItem("password") || "";
  const history = useNavigate();

  const token = localStorage.getItem("token");
  if (token) {
    history("/landing");
  }

  const [username, setUsername] = useState(storedUsername);
  const [password, setPassword] = useState(storedPassword);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleRememberCredentials = () => {
    setRememberCredentials(!rememberCredentials);
    // Store values in localStorage based on the updated state
    if (!rememberCredentials) {
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
    } else {
      // Clear values from localStorage if "Remember Me" is unchecked
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    }
  };

  const login = async (e) => {
    e.preventDefault();
    const phpScriptPath = serverPath+"/Onboarding/api/login.php";

    try {
      const response = await fetch(phpScriptPath, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      });

      const data = await response.json();

      if (data.message === "success") {
        localStorage.setItem("token", data.token);
        localStorage.setItem("userData", JSON.stringify(data.data));
        
        setSuccessMessage("Successful!");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);

        

      } else if (
        data.message === "failed" ||
        data.message === "incorrectpass"
      ) {
        setErrorMessage(
          "Incorrect username or password. Please check your credentials"
        );
      } else if (data.message === "invalidemail") {
        setErrorMessage("User not found. Create an account.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const clearSuccess = () => {
    setSuccessMessage("");
  };

  return (
    <div className="main-container">
      <main>
        <h1 className="loginhead">Galattica Onboarding Login</h1>
        <section className="login-box">
          <form onSubmit={login}>
            <div className="bspaces">
              <svg
                fill="#ffffff"
                width="30px"
                height="30px"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <path d="M858.5 763.6a374 374 0 0 0-80.6-119.5 375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z" />
              </svg>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onClick={clearError}
                required
              />
            </div>
            <div className="bspaces">
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 6V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V6M9 11H15C15.5523 11 16 10.5523 16 10V7C16 6.44772 15.5523 6 15 6H9C8.44772 6 8 6.44772 8 7V10C8 10.5523 8.44772 11 9 11ZM5 21H19C20.1046 21 21 20.1046 21 19V16C21 14.8954 20.1046 14 19 14H5C3.89543 14 3 14.8954 3 16V19C3 20.1046 3.89543 21 5 21Z"
                  stroke="#ffffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="7.5" cy="17.5" r="1.5" fill="#ffffff" />
                <circle cx="12" cy="17.5" r="1.5" fill="#ffffff" />
                <circle cx="16.5" cy="17.5" r="1.5" fill="#ffffff" />
              </svg>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onClick={clearError}
                required
              />
            </div>
            <div className="bspaces">
              <button type="submit">Log In</button>
            </div>
            <div className="bspaces">
              <p>
                Remember Me
                  <input
                  type="checkbox"
                  checked={rememberCredentials}
                  onChange={handleRememberCredentials}
                />
              </p>
              
            </div>
          </form>
          {errorMessage && (
            <p style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</p>
          )}
          {successMessage && (
            <p style={{ color: "green", fontWeight: "bold" }}>
              {successMessage}
            </p>
          )}
          <p>
            Don't have an account? <Link to="/signup">Signup</Link>
          </p>
        </section>
      </main>
    </div>
  );
};

export default Login;
