import React from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import "./Performance.css";

const PerformanceCard = ({ title, description }) => (
  <Card className="card">
    <CardBody>
      <CardTitle tag="h3">{title}</CardTitle>
      <CardText>{description}</CardText>
    </CardBody>
  </Card>
);

const Performance = () => {
  const performanceData = [
    {
      title: "Productivity 📈",
      description:
        "At Galattica, we emphasize the importance of productivity. We expect our employees to consistently deliver high-quality work in a timely manner. This means efficiently managing tasks and projects to meet deadlines while maintaining a focus on excellence.",
    },
    {
      title: "Accountability 🤝",
      description:
        "Taking ownership of one's work and actions is a fundamental value at Galattica. We believe in a culture of accountability, where employees are responsible for their tasks and outcomes. Being accountable means being reliable and ensuring that commitments are met with diligence and integrity.",
    },
    {
      title: "Collaboration 🤝",
      description:
        "Collaboration is a key driver of success in our workplace. We expect our employees to work seamlessly with others and embrace a team-oriented approach. Being a team player involves effective communication, active participation, and a willingness to contribute to collective goals.",
    },
    {
      title: "Innovation 🚀",
      description:
        "Galattica encourages a spirit of innovation among its employees. We expect our team members to challenge the status quo, think creatively, and continuously seek opportunities for improvement. Embracing innovation leads to growth and positive change, contributing to our collective success.",
    },
  ];

  return (
    <div className="perform">
      <h2 className="text-center">Performance Evaluation</h2>
      <p>
      We set the bar high and hold our employees accountable. We evaluate performance based on:
      </p>
      <br></br>
      <div className="row">
        {performanceData.map((card, index) => (
          <div key={index} className="col-md-6">
            <PerformanceCard
              title={card.title}
              description={card.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Performance;
