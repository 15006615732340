import React from "react";

const CourseContent = ({ lesson, lessons, onLessonChange }) => {
  return (
    <div>
      <h2>{lesson.title}</h2>
      <p>{lesson.description}</p>
    </div>
  );
};

export default CourseContent;
