import React from 'react';
import { Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Procedures.css';

const Procedures = ({ procedures }) => {
  
  return (
    <div className="procedures-list">
      <h1 className='text-center'>Culture, procedures and policies</h1>
      <div className='container-fluid'>
        <div className='row'>
          {procedures.map((procedure) => (
            <div className='col-lg-4' key={procedure.id}>
              <Card className="procedure">
                <CardBody>
                  <CardTitle tag="h2">{procedure.title}</CardTitle>
                  <CardText>{procedure.description}</CardText>
                  <Link to={`../procedures-preview/${procedure.id}`}>
                    <Button className="btn btn-success">
                      More
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const proceduresData = [
  {
    id: 1,
    title: 'Culture',
    description: 'Respect and Fairness at Galattica',
  },
  {
    id: 2,
    title: 'Procedures',
    description: 'Fundamental Principles at Galattica',
  },
  {
    id: 3,
    title: 'Policies',
    description: 'Policies at GALATTICA',
  },
];

export default function ProceduresContainer() {

  return (
    <div className="content">
      <Procedures procedures={proceduresData} />
    </div>
  );
}
