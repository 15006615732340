import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import routes from "routes.js";
import logo from "assets/img/GalatticaLogo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

function Admin() {
  const [idleTimer, setIdleTimer] = useState(null);
  const location = useLocation();
  const mainPanelRef = useRef(null);
  const [sidebarOpened, setSidebarOpened] = useState(false);

  useEffect(() => {
    
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "../#/login";
    }
     let ps;
    const isWin = navigator.platform.indexOf("Win") > -1;

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      setIdleTimer(setTimeout(logout, 15 * 60 * 1000)); // 10 seconds in milliseconds
    };

    const handleBeforeUnload = (event) => {
      const token = localStorage.getItem("token");
      if (token) {
        localStorage.setItem("lastPathname", location.pathname);
        resetIdleTimer();
      }
    };

    const handleUserActivity = () => {
      resetIdleTimer();
    };

    if (isWin) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });

      const tables = document.querySelectorAll(".table-responsive");
      tables.forEach((table) => {
        new PerfectScrollbar(table);
      });

      window.addEventListener("beforeunload", handleBeforeUnload);
      document.addEventListener("mousemove", handleUserActivity);
      document.addEventListener("keydown", handleUserActivity);

      return () => {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        window.removeEventListener("beforeunload", handleBeforeUnload);
        document.removeEventListener("mousemove", handleUserActivity);
        document.removeEventListener("keydown", handleUserActivity);
      };
    }
  }, [idleTimer]);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      const tables = document.querySelectorAll(".table-responsive");
      tables.forEach((table) => {
        new PerfectScrollbar(table);
      });
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  const resetInactivityTimer = () => {
    clearTimeout(idleTimer);
    setIdleTimer(setTimeout(()=>{}, 10 * 60 * 1000));
  };
  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpened(!sidebarOpened);
    resetInactivityTimer();
  };

  const getRoutes = (routes) => {
    return routes.flatMap((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.children) {
          const childRoutes = prop.children.map((child, childKey) => (
            <Route
              path={child.path}
              element={child.component}
              key={`${key}-${childKey}`}
              exact
            />
          ));
          return [
            <Route path={prop.path} element={prop.component} key={key} exact />,
            ...childRoutes,
          ];
        } else {
          return (
            <Route path={prop.path} element={prop.component} key={key} exact />
          );
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Galattica";
  };

  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <div
          className="wrapper"
          onMouseMove={resetInactivityTimer}
          onKeyDown={resetInactivityTimer}
        >
          <Sidebar
            routes={routes}
            logo={{
              outterLink: "https://galattica.co.za",
              text: "Galattica",
              imgSrc: logo,
            }}
            toggleSidebar={toggleSidebar}
          />
          <div className="main-panel" ref={mainPanelRef}>
            <AdminNavbar
              brandText={getBrandText(location.pathname)}
              toggleSidebar={toggleSidebar}
              sidebarOpened={sidebarOpened}
            />
            <Routes>{getRoutes(routes)}</Routes>
          </div>
          <FixedPlugin bgColor={color} handleBgClick={changeColor} />
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
