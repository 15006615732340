import React, { useState } from "react";
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "./courses.css";

const CourseList = ({ courses }) => {
  //const [courses, setCourses] = useState([]);
  const token=localStorage.getItem('token');
  if (!token) {
    window.location.href='/login';
  }
  return (
    <div className="course-list">
      <h1 className="text-center">Course List</h1>
      <div className="container-fluid">
        <div className="row">
          {courses.map((course) => (
            <div className="col-lg-4">
              <Card key={course.id} className="course">
                <CardBody>
                  <CardTitle tag="h5">{course.title}</CardTitle>
                  <CardText>{course.description}</CardText>
                  <Link to={`../course-preview/${course.id}`}>
                    <Button className="btn btn-success">More</Button>
                  </Link>
                </CardBody>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const coursesData = [
  {
    id: 1,
    title: "React Masterclass",
    description: "Learn React from scratch to expert level",
    image: "https://example.com/react-masterclass.png",
    enrollLink: "https://example.com/enroll/react-masterclass",
    courseLink: "https://example.com/courses/react-masterclass",
  },
  {
    id: 2,
    title: "React Masterclass",
    description: "Learn React from scratch to expert level",
    image: "https://example.com/react-masterclass.png",
    enrollLink: "https://example.com/enroll/react-masterclass",
    courseLink: "https://example.com/courses/react-masterclass",
  },
  {
    id: 3,
    title: "React Masterclass",
    description: "Learn React from scratch to expert level",
    image: "https://example.com/react-masterclass.png",
    enrollLink: "https://example.com/enroll/react-masterclass",
    courseLink: "https://example.com/courses/react-masterclass",
  },
];

export default function Courses() {
  const [loaded, setLoaded] = useState(false);
  const [courses, setCourses] = useState([]);

  /*useEffect(() => {
    fetch('https://example.com/courses')
      .then((response) => response.json())
      .then((data) => {
        setCourses(data);
        setLoaded(true);
      });
  }, []);*/

  return (
    <div className="content">
      <CourseList courses={coursesData} />
    </div>
  );
}
